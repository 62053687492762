/* index.css */
@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
   font-family: "Geologica", sans-serif;
   overflow-x: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.caf-glass-effect {
   /* background-color: rgba(0, 0, 0, 0); */
   backdrop-filter: blur(6px);
   background: linear-gradient(74.44deg, rgba(0, 0, 0, 0.4) -9.91%, rgba(255, 255, 255, 0.01) 124.9%);
}
.glass-effect {
   backdrop-filter: blur(6px);
   -webkit-backdrop-filter: blur(10px);
   background: linear-gradient(74.44deg, rgba(0, 0, 0, 0.2) -9.91%, rgba(255, 255, 255, 0.01) 124.9%);
   border: 0px solid rgba(255, 255, 255, 0.3); /* Subtle border color */
   border-radius: 0.5rem; /* Increased radius for more rounded corners */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
   transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
   overflow: hidden; /* Ensures the content is clipped to the rounded border */
}
.glass-effect2 {
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   background: rgba(255, 255, 255, 0.1);

   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
}
.glass-effect3 {
   background: rgba(255, 255, 255, 0.1);

   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
}
.glass-effect4 {
   background: rgba(255, 255, 255, 0.08);

   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
}
.glass-effect-noborder {
   backdrop-filter: blur(5px); /* Reduced blur for a lighter effect */
   -webkit-backdrop-filter: blur(5px); /* Reduced blur for a lighter effect */
   background: linear-gradient(235.34deg, rgba(175, 255, 228, 0.2) 5.78%, rgba(20, 20, 20, 0.2) 73.71%);

   border-radius: 1rem; /* Increased radius for more rounded corners */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
   transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
   overflow: hidden; /* Ensures the content is clipped to the rounded border */
}
.glass-effect-noborder-inverted {
   backdrop-filter: blur(5px); /* Reduced blur for a lighter effect */
   -webkit-backdrop-filter: blur(5px); /* Reduced blur for a lighter effect */

   border-radius: 1rem; /* Increased radius for more rounded corners */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
   transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
   overflow: hidden; /* Ensures the content is clipped to the rounded border */

   background: linear-gradient(85.6deg, rgba(255, 255, 255, 0.2) -2.61%, rgba(20, 20, 20, 0.2) 100.69%);
}

.bg-test {
   background: linear-gradient(235.34deg, rgba(255, 255, 255, 0.2) 5.78%, rgba(20, 20, 20, 0.2) 73.71%);
}
.bg-test-2 {
   background: linear-gradient(180deg, #024c4a 0%, rgb(45, 178, 173, 0.3) 100%);
}
.bg-test-3 {
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 5.78%, rgba(20, 20, 20, 0.2) 73.71%);
}
.bg-test-3-2 {
   background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 5.78%, rgba(20, 20, 20, 0.2) 73.71%);
}
.bg-test-4 {
   background: linear-gradient(180deg, #024c4a 0%, rgba(5, 178, 173, 0.3) 100%);
}
.bg-test-5 {
   background: linear-gradient(260.29deg, rgba(5, 178, 173, 0.3) -242.18%, #024c4a 98.28%);
}
.bg-test-6 {
   background: linear-gradient(180deg, #02514d 0%, #000000 100%);
}
.bg-test-7 {
   background: linear-gradient(90deg, #024c4a 0%, #000000 100%);
}
.bg-test-8 {
   background: linear-gradient(90deg, #146260 0%, #0a3130 100%);
}
.glass-effect-noborder-literally {
   backdrop-filter: blur(10px) saturate(180%);
   -webkit-backdrop-filter: blur(10px) saturate(180%);
   background: rgba(255, 255, 255, 0.2);

   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
   transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
   overflow: hidden; /* Ensures the content is clipped to the rounded border */
}
.glass-effect-literally {
   backdrop-filter: blur(10px) saturate(180%);
   -webkit-backdrop-filter: blur(10px) saturate(180%);
   background: linear-gradient(180deg, #ababab2c 0%, #02514d5e 100%);

   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
   transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
   overflow: hidden; /* Ensures the content is clipped to the rounded border */
}

.line {
   width: 150px; /* Ajusta este valor para hacer la línea más larga */
   height: 4px;
   background-color: white;
   margin: 0 auto 10px;
}
.line-big {
   width: 180px; /* Ajusta este valor para hacer la línea más larga */
   height: 8px;
   background-color: white;
   margin: 0 auto 10px;
}
.line-big-2 {
   width: 200px; /* Ajusta este valor para hacer la línea más larga */
   height: 8px;
   background-color: white;
   margin: 0 auto 10px;
}
.line-big-3 {
   width: 150px; /* Ajusta este valor para hacer la línea más larga */
   height: 6px;
   background-image: linear-gradient(90deg, #024c4a 0%, #9bb6b3 100%);
   margin: 0 auto 10px;
}
.line-big-4 {
   width: 100px; /* Ajusta este valor para hacer la línea más larga */
   height: 10px;
   background-image: linear-gradient(90deg, #024c4a 0%, #9bb6b3 100%);
   margin: 0 auto 10px;
}
.line-big-5 {
   width: 60px; /* Ajusta este valor para hacer la línea más larga */
   height: 5px;
   background-image: linear-gradient(90deg, #024c4a 0%, #9bb6b3 100%);
   margin: 0 auto 10px;
}
.line-big-6 {
   width: 100%; /* Ajusta este valor para hacer la línea más larga */
   height: 50px;
   background-image: linear-gradient(90deg, #024c4a 0%, #010706 100%);
}

.line2 {
   width: 200px; /* Ajusta este valor para hacer la línea más larga */
   height: 4px;
   background-color: white;
   margin: 0 0 10px; /* Cambia `margin: 0 auto 10px` a `margin: 0 0 10px` */
}
.line-short {
   width: 150px; /* Ajusta este valor para hacer la línea más corta */
   height: 10px; /* Ajusta este valor para hacer la línea más gruesa */
   background-color: white;
   margin: 0 0 10px 0; /* Alinea la línea a la izquierda */
}
.line-short-2 {
   width: 300px; /* Ajusta este valor para hacer la línea más corta */
   height: 8px; /* Ajusta este valor para hacer la línea más gruesa */
   background-color: white;
   margin: 0 0 10px 0; /* Alinea la línea a la izquierda */
}

.line3 {
   width: 160px; /* Ajusta este valor para hacer la línea más larga */
   height: 6px;
   background-color: white;
   margin: 0 auto 10px;
}

.diagonal-background::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 0;
   height: 0;
   border-top: calc(50vh + 1px) solid transparent;
   border-right: 120vw solid black;
   z-index: -1;
}
@media (max-width: 767px) {
   #hero-background-image {
      clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 0% 70%);
   }
}
.diagonal-background::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 0;
   height: 0;
   border-bottom: calc(50vh + 1px) solid black;
   border-right: 100vw solid black;
   z-index: -1;
}

.rounded-gradient-border {
   position: relative;
   border-bottom-left-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
   background-clip: padding-box;
}

.rounded-gradient-border::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: linear-gradient(to top, #9bb6b3, rgba(2, 76, 74, 0.3));
   border-radius: 0.5rem;
   mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   mask-composite: exclude;
   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   padding: 2px;
}
.rounded-gradient-border2 {
   position: relative;
   border-radius: 1rem;
   background-clip: padding-box;
}

.rounded-gradient-border2::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: linear-gradient(to bottom, #05b7ae, rgba(5, 183, 174, 0));
   border-radius: 1rem;
   mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   mask-composite: exclude;
   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   padding: 2px;
}
.rounded-gradient-border3 {
   position: relative;
   border-radius: 1rem; /* Redondea todas las esquinas */
   background-clip: padding-box;
}

.rounded-gradient-border3::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: linear-gradient(to top, #05b7ae, #02514d);
   border-radius: 1rem; /* Redondea todas las esquinas */
   mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   mask-composite: exclude;
   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   padding: 2px;
}
.rounded-gradient-border4 {
   position: relative;
   border-radius: 1rem; /* Redondea todas las esquinas */
   background-clip: padding-box;
}

.rounded-gradient-border4::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: linear-gradient(to top, #9bb6b3, #02514d);
   border-radius: 1rem; /* Redondea todas las esquinas */
   mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   mask-composite: exclude;
   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   padding: 2px;
}
/* styles.css */

/* Elimina los puntos predeterminados de slick-carousel */
.slick-dots li button:before {
   content: none;
}

/* Ajusta la posición de los puntos */
.slick-dots {
   bottom: -30px; /* Ajusta según sea necesario */
}

/* Asegura que los puntos estén centrados */
.slick-dots li {
   margin: 0 8px;
}

/* Personaliza los puntos activos e inactivos */
.slick-dots li div {
   transition: background-color 0.3s, border-color 0.3s;
}

.slick-dots li.slick-active div {
   background-color: #024c4a; /* Punto activo */
}

.slick-dots li div {
   background-color: white; /* Puntos inactivos */
   border: 2px solid #024c4a; /* Borde de color */
}

.custom-modal-container .swal2-html-container {
   padding: 0; /* Elimina el padding del contenido */
}

div:where(.swal2-container) div:where(.swal2-popup) {
   background: transparent !important; /* Elimina el fondo del modal */
}
/* Estilos para el modal en sí */
.custom-modal-popup {
   border-radius: 20px; /* Hace que el modal sea redondeado */
   max-width: 90%; /* Limita el ancho máximo a un 90% del viewport en pantallas pequeñas */
   width: 800px; /* Ancho por defecto en pantallas grandes */
   max-height: 90vh; /* Limita la altura máxima al 90% de la altura del viewport */

   padding: 0px; /* Espaciado interno del modal */
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
   .custom-modal-popup {
      width: 90%; /* Cambia el ancho a un 90% del viewport para pantallas pequeñas */
      border-radius: 10px; /* Puede ajustar el radio para pantallas pequeñas */
   }
}

.confirm-button {
   color: #fff3b6 !important; /* Color de texto para el botón de confirmar */
}

.cancel-button {
   color: #02514d !important; /* Color de texto para el botón de cancelar */
}

.custom-back {
   background: linear-gradient(to right, transparent, rgba(0, 0, 0, 1), black);
}
